<script setup lang="ts">
import { type BadgeDescription, type Description, type IconDescription, type LinkDescription, type RouteDescription, type TextDescription } from 'types';
import BadgeDescriptionVue from './BadgeDescription.vue';
import IconDescriptionVue from './IconDescription.vue';
import LinkDescriptionVue from './LinkDescription.vue';
import RouteDescriptionVue from './RouteDescription.vue';
import TextDescriptionVue from './TextDescription.vue';

defineProps<{
    description: Description
}>();

type Parts = (
    BadgeDescription
    | IconDescription
    | LinkDescription
    | RouteDescription
    | TextDescription
);

function component(part: Parts) {
    if(part.type === 'badge') {
        return BadgeDescriptionVue;
    }

    if(part.type === 'icon') {
        return IconDescriptionVue;
    }

    if(part.type === 'link') {
        return LinkDescriptionVue;
    }

    if(part.type === 'route') {
        return RouteDescriptionVue;
    }

    if(part.type === 'text') {
        return TextDescriptionVue;
    }
}
</script>

<template>
    <div class="[&_span:not(:first-child)]:before:content-['_']">
        <span
            v-for="(part, i) in description.parts"
            :key="i">
            <!-- @vue-ignore -->
            <Component
                :is="component(part)"              
                :description="part" />
        </span>
    </div>
</template>