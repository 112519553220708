<script lang="ts" setup generic="T extends BaseModel">
import { ChevronDownIcon, ChevronUpIcon, ExclamationTriangleIcon, PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { Link, router } from '@inertiajs/vue3';
import { Pagination } from '@vue-interface/pagination';
import { format } from 'date-fns';
import { debounce } from 'lodash-es';
import { parse } from 'qs';
import type { BaseModel, LengthAwarePaginator } from 'types';
import type { Component, RenderFunction } from 'vue';
import { capitalize, computed, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import ActionButton from './ActionButton.vue';
import Header from './Header.vue';
import Skeleton from './Skeleton.vue';
import SearchField from './filters/SearchField.vue';

export type LaraveLinks = {
    label: string,
    url: string | null,
    active: boolean
}[]

type EchoContext = {
    reload: () => void;
    models: T[];
}

type Echo = {
    listen: (context: EchoContext) => void;
    stopListening: (context: EchoContext) => void;
}

const props = withDefaults(defineProps<{
    response?: LengthAwarePaginator<T>;
    singular?: string;
    plural?: string;
    namespace?: string;
    only?: string|string[];
    title?: (model: T) => string|undefined;
    description?: (model: T) => string|undefined;
    params?: Record<string, any>;
    routes?: {
        index?: string;
        create?: string;
        delete?: (model: T) => string;
        edit?: (model: T) => string;
        show?: (model: T) => string;
    };
    can?: {
        update?: (model: T) => boolean|undefined;
        view?: (model: T) => boolean|undefined;
        delete?: (model: T) => boolean|undefined;
        create?: boolean;
    };
    filters?: boolean;
    paginate?: boolean;
    searchable?: boolean;
    sortable?: boolean;
    deleteLabel?: string;
    deleteIcon?: Component;
    defaultSort?: string;
    header?: string|false;
    headerDescription?: string;
    icons?: {
        default?: Component | RenderFunction | string;
        list?: (model: T) =>  Component | RenderFunction | string;
        header?: Component | RenderFunction | string;
        empty?: Component | RenderFunction | string;
    };
    badges?: (model: T) => string[];
    size?: 'sm' | 'md' | 'lg';
    echo?: Echo;
    tableHeaders: {
        name: string;
        label?: string;
        class?: string;
    }[];
}>(), {
    response: undefined,
    singular: undefined,
    plural: undefined,
    namespace: undefined,
    only: undefined,
    title: (model: T) => {
        if('name' in model) {
            return model.name as string;
        }

        if('title' in model) {
            return model.title as string;
        }

        return model.id?.toString();
    },
    description: (model: T) => `Updated on ${model.updated_at && format(new Date(model.updated_at), 'PPPp')}`,
    routes: undefined,
    can: () => ({
        create: true,
        delete: () => true,
        update: () => true,
        view: () => true,
    }),
    params: () => ({}),
    badges: () => [],
    filters: true,
    paginate: true,
    defaultSort: 'created_at,desc',
    deleteLabel: 'delete',
    deleteIcon: undefined,
    header: undefined,
    headerDescription: undefined,
    icons: () => ({
        default: undefined,
        list: undefined,
        header: undefined,
        empty: undefined,
    }),
    size: 'lg',
    searchable: true,
    sortable: true,
    echo: undefined
});

const queryParams = computed(() => {
    const parsed = parse(location.search.replace(/^\?/, ''));

    if(props.namespace) {
        return Object.assign({}, parsed[props.namespace], props.params);
    }

    return Object.assign({}, parsed, props.params);
});

const order = ref<string|undefined>(queryParams.value?.order);
const page = ref<number|undefined>(queryParams.value?.page);
const singularName = computed(() => props.singular?.toLowerCase());
const pluralName = computed(() => props.plural?.toLowerCase());
const formData = reactive<Record<string,any>>(Object.assign({}, queryParams.value));

const orderColumn = computed<string|undefined>(() => order.value?.split(',')[0]);
const orderDirection = computed<string|undefined|'asc'>(() => order.value?.split(',')[1]);

const only = computed(() => {
    if(Array.isArray(props.only)) {
        return [...props.only, 'flash'];
    }

    if(props.only) {
        return [props.only, 'flash'];
    }

    if(pluralName.value) {
        return [pluralName.value, 'flash'];
    }
    
    return ['flash'];
});

const requestData = computed(() => {
    const data = {
        ...formData,
        order: order.value,
        page: page.value
    };

    if(!props.namespace) {
        return data;
    }

    return { [props.namespace]: data };
});

const debounced = debounce((key: string, modelValue: any) => {
    formData[key] = modelValue;
}, 333);

function onPaginate(value: number) {
    page.value = value;

    reload();
}

const icons = computed(() => ({
    default: props.icons.default,
    list: (model: T) => props.icons?.list?.(model) ?? props.icons.default,
    header: props.icons.header ?? props.icons.default,
    empty: props.icons.empty ?? props.icons.default,
}));

function reload() {
    router.reload({
        data: requestData.value,
        only: [...only.value],
    });    
}

function onClickOrder(name: string) {
    if(orderColumn.value !== name) {
        order.value = `${name},desc`;
    }
    else {
        switch (orderDirection.value) {
        case undefined:
            order.value = `${name},desc`;
            break;
        case 'desc':
            order.value = `${name},asc`;
            break;
        case 'asc':
            order.value = undefined;
            break;
        }
    }
}

function onClickDelete(model: T) {
    if(!confirm(`Are you sure you want to ${props.deleteLabel} this ${singularName.value}?`) || !props.routes?.delete) {
        return;
    }

    router.delete(props.routes.delete(model), {
        preserveScroll: true,
        only: only.value
    });
}

defineExpose({
    reload
});

watch(formData, () => page.value = undefined);
watch(requestData, reload);

onBeforeMount(() => {
    watch(() => props.response, (value, oldValue) => {
        if(oldValue) {
            props.echo?.stopListening({ models: oldValue.data, reload });
        }

        if(value) {
            props.echo?.listen({ models: value.data, reload });
        }
    });
});

onMounted(() => reload());

onBeforeUnmount(() => {
    props.echo?.stopListening({
        reload,
        models: props.response?.data ?? []
    });
});
</script>

<template>
    <Header
        v-if="header !== false"
        v-bind="{
            title: header ?? (pluralName && capitalize(pluralName)),
            icon: icons.header,
            size,
            description: headerDescription,
        }">
        <template #actions>
            <slot name="actions" />

            <slot
                name="create-action">
                <Link
                    v-if="routes?.create && can.create"
                    :href="routes?.create"
                    class="btn btn-primary capitalize">
                    Create {{ singularName }}
                </Link>
            </slot>
        </template>
    </Header>
    
    <div class="flex flex-col gap-4">
        <slot
            v-if="filters"
            name="filters">
            <div class="flex items-end">
                <div class="flex flex-1 items-end gap-2">
                    <slot
                        v-if="searchable"
                        name="search">
                        <SearchField
                            :model-value="formData.q"
                            class="w-full max-w-80"
                            @reset="formData.q = undefined"
                            @update:model-value="value => debounced('q', value)" />
                    </slot>
                    <slot
                        name="left-filters"
                        v-bind="{ formData, debounced }" />
                </div>
                <div class="flex flex-1 justify-end gap-2">
                    <slot
                        name="right-filters"
                        v-bind="{ formData, debounced }" />
                </div>
            </div>
        </slot>
            
        <table class="shadow w-full border-spacing-0 border-separate">
            <thead
                class="
                [&>tr>th:first-child]:rounded-tl
                [&>tr>th:last-child]:rounded-tr
                [&>tr>th:first-child]:border-l
                [&>tr>th:last-child]:border-r
                [&>tr>th]:border-t
                [&>tr>th]:border-neutral-700
                [&>tr>th]:font-semibold
                [&>tr>th]:bg-white
                [&>tr>th]:dark:bg-neutral-800
                [&>tr>th]:py-3
                [&>tr>th]:px-4
                [&>tr>th]:text-left">
                <tr>
                    <th
                        v-for="{ name, label, class: className } of tableHeaders"
                        :key="`th-${name}`"
                        :class="className">
                        <button
                            type="button"
                            class="flex items-center gap-2 hover:underline [&:hover>svg]:visible"
                            :class="{
                                '[&>svg]:invisible': orderColumn !== name,
                                '[&>.asc]:hidden': orderColumn !== name || orderColumn === name && orderDirection === 'desc',
                                '[&>.desc]:hidden': orderColumn === name && orderDirection === 'asc'
                            }"
                            @click="onClickOrder(name)">
                            {{ label }}
                            
                            <ChevronUpIcon
                                class="asc size-4" />
                            <ChevronDownIcon
                                class="desc size-4" />
                        </button>
                    </th>
                    <th />
                </tr>
            </thead>
            <tbody
                class="
                [&>tr>td:first-child]:border-l
                [&>tr>td:last-child]:border-r
                [&>tr>td]:border-b
                [&>tr:last-child>td:first-child]:rounded-bl
                [&>tr:last-child>td:last-child]:rounded-br
                [&>tr>td]:border-neutral-700
                [&>tr>td]:bg-neutral-900
                [&>tr>td]:py-3
                [&>tr>td]:px-4">
                <template v-if="!response">
                    <tr
                        v-for="i in 5"
                        :key="i">
                        <td
                            v-for="{ name } of tableHeaders"
                            :key="`skeleton-${name}`"
                            class="py-3 px-4">
                            <Skeleton
                                class="h-6 rounded-none first:rounded-t last:rounded-b" />
                        </td>
                        <td>
                            <Skeleton
                                class="h-6 rounded-none first:rounded-t last:rounded-b" />
                        </td>
                    </tr>
                </template>
                <template v-else-if="response.data.length">
                    <tr
                        v-for="(model, i) in response.data"
                        :key="`row-${i}`">
                        <td
                            v-for="{ name } of tableHeaders" 
                            :key="`th-${name}`">
                            <slot
                                :name="name"
                                v-bind="model">
                                <template v-if="i === 0 && (model as any)[name] && routes?.show">
                                    <Link
                                        :href="routes.show(model)"
                                        class="text-rose-500 hover:underline">
                                        {{ (model as any)[name] }}
                                    </Link>
                                </template>
                                <template v-else>
                                    {{ (model as any)[name] ?? '&mdash;' }}
                                </template>
                            </slot>
                        </td>
                        <td class="text-right">
                            <slot
                                name="before-table-action"
                                :model="model" />
                            <slot
                                name="table-actions"
                                :model="model">
                                <ActionButton
                                    size="sm">
                                    <Link
                                        v-if="routes?.edit && can.update?.(model)"
                                        :href="routes?.edit(model)"
                                        class="group flex items-center capitalize">
                                        <PencilSquareIcon class="my-1 mr-3 size-5" /> Edit {{ singularName }}
                                    </Link>

                                    <slot
                                        name="list-actions-links"
                                        :model="model" />

                                    <hr v-if="routes?.edit && can.update?.(model) && routes?.delete && can.delete?.(model)">

                                    <button
                                        v-if="routes?.delete && can.delete?.(model)"
                                        class="group flex items-center capitalize"
                                        @click="() => onClickDelete(model)">
                                        <component
                                            :is="deleteIcon ?? TrashIcon"
                                            class="my-1 mr-3 size-5" />
                                        {{ deleteLabel }} {{ singularName }}
                                    </button>
                                </ActionButton>
                                <slot
                                    name="after-list-action"
                                    :model="model" />
                            </slot>
                        </td>
                    </tr>
                </template>
                <template v-else>
                    <tr>
                        <td
                            :colspan="tableHeaders.length + 1">
                            <div class="flex items-center gap-2">
                                <ExclamationTriangleIcon class="size-6" />
                                There are no records found.
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        
        <template v-if="response">
            <template v-if="'last_page' in response">
                <Pagination
                    v-if="paginate && response"
                    align="center"
                    :page="response.current_page"
                    :total-pages="response.last_page"
                    @paginate="onPaginate" />
            </template>
            <div class="flex items-center justify-between">
                <button
                    :disabled="!response.prev_page_url"
                    class="btn btn-primary"
                    @click="onPaginate(response.current_page - 1)">
                    Prev
                </button>
                <button
                    :disabled="!response.next_page_url"
                    class="btn btn-primary"
                    @click="onPaginate(response.current_page + 1)">
                    Next
                </button>
            </div>
        </template>
    </div>    
</template>