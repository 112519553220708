// import { router } from '@inertiajs/vue3';
import { CalendarDaysIcon, ExclamationTriangleIcon, InboxIcon, NoSymbolIcon, PaperAirplaneIcon, RocketLaunchIcon } from '@heroicons/vue/24/outline';
import { format } from 'date-fns';
import type Echo from 'laravel-echo';
import type { Channel } from 'laravel-echo';
import type { Agency, AgencyUser, SubscriberList, User } from 'types';
import { useToast } from 'vue-toastification';
import MessageGearsIcon from '../svg/message-gears-icon.svg';

const toast = useToast();

export type RequiredChannelProps = {
    authUser: User;
    agencies: (Agency & {pivot: AgencyUser})[];
    lists: SubscriberList[];
};

export default ({ echo, props }: { echo: Echo<any>, props: RequiredChannelProps }) => {
    const channels: Channel[] = [];

    if(!props.authUser.super_admin) {
        for(const agency of props.agencies) {
            if(agency.pivot.role === 'admin') {
                channels.push(echo.private(`App.Models.Agency.${agency.id}`));
            }
            else {
                for(const list of props.lists.filter(({ agency_id }) => agency_id === agency.id)) {
                    channels.push(echo.private(`App.Models.SubscriberList.${list.id}`));
                }
            }
        }
    }

    for(const channel of channels) {
        channel
            .listen('SendCancelled', (e: any) => toast.warning(`"${e.send.name}" was cancelled for ${format(new Date(e.date), 'PPPp')}!`, {
                icon: NoSymbolIcon
            }))
            .listen('SendDelivered', (e: any) => toast.success(`"${e.send.name}" was delivered at ${format(new Date(e.send.delivered_at), 'PPPp')}!`, {
                icon: InboxIcon
            }))
            .listen('SendTemplateCreated', (e: any) => toast.info(`"${e.send.name}" was sent to MessageGears as a template!`, {
                icon: MessageGearsIcon
            }))
            .listen('SendFailed', (e: any) => toast.error(`"${e.send.name}" failed to send!`, {
                icon: ExclamationTriangleIcon
            }))
            .listen('SendLaunched', (e: any) => toast.info(`"${e.send.name}" was launched!`, {
                icon: RocketLaunchIcon
            }))
            .listen('SendLaunchedNow', (e: any) => toast.success(`"${e.send.name}" was sent!`, {
                icon: PaperAirplaneIcon
            }))
            .listen('SendScheduled', (e: any) => toast.info(`"${e.send.name}" was scheduled for ${format(new Date(e.send.send_at), 'PPPp')}!`, {
                icon: CalendarDaysIcon
            }));
    }
};