<script lang="ts" setup>
import type { IBeeConfig, IEntityContentJson } from '@mailupinc/bee-plugin/dist/types/bee';
import { Card } from '@vue-interface/card';
import { InputField } from '@vue-interface/input-field';
import { SelectField } from '@vue-interface/select-field';
import { TextareaField } from '@vue-interface/textarea-field';
import { useForm } from 'laravel-precognition-vue-inertia';
import type { FeedSource, SavedRow, SubscriberList, Template, User } from 'types';
import { ref } from 'vue';
import AutoSave from './AutoSave.vue';
import BeeEditor from './BeeEditor.vue';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';

const props = defineProps<{
    savedRow?: SavedRow,
    template?: Template
    schemaUrl: string,
    feedSources: FeedSource[],
    url: string
    authUser: User
    list: SubscriberList
}>();

const beeLoaded = ref(false);
const bee = ref<typeof BeeEditor>();
const method = props.savedRow?.id ? 'put' : 'post';

const form = useForm(method, props.url,{
    name: undefined,
    modelable_id: props.template?.id,
    modelable_type: 'template',
    html: undefined,
    template_json: undefined,
    type: 'feed_story',
    options: {
        total_days_in_feed: 3,
        feed_source_ids: [],
    },
    ...props.savedRow
});

function beeConfig(type?: string): Partial<IBeeConfig> {
    switch (type) {
    case 'feed_story':
        return {
            rowsConfiguration: {
                emptyRows: false,
                defaultRows: false,
                externalContentURLs: [{
                    name: 'Choose a story...',
                    value: props.schemaUrl
                }]
            },
        };
    default: 
        return {
            
        };
    }
}

function onLoadBee() {
    beeLoaded.value = true;
}

function onChangeBee(templateJson: IEntityContentJson) {
    form.template_json = templateJson;

    form.id && props.authUser.options?.auto_save && form.patch(props.url, {
        preserveScroll: true
    });
}

function onSubmit() {
    (bee.value as any)?.bee.save();
}

function onSaveBee(templateJson: IEntityContentJson, html: string) {
    form.template_json = templateJson;
    form.html = html;

    form.submit(method, props.url, {
        onError: () => {
            beeLoaded.value = true;
        }
    });
}
</script>

<template>
    <FormLayout
        :submit="onSubmit"
        :button-label="form.id ? 'Save' : 'Create'"
        :disabled="!beeLoaded || form.processing"
        :activity="!beeLoaded || form.processing">
        <div class="flex flex-col gap-y-8">
            <Card class="bg-white dark:bg-neutral-950 border border-neutral-200 dark:border-neutral-700">
                <template #header>
                    <h2 class="p-2 font-bold text-lg border-b border-neutral-200 dark:border-neutral-700">
                        Details
                    </h2>
                </template>
                <template #body>
                    <FormField
                        label="Name"
                        description="This is how the named row will be displayed in Bee.">
                        <InputField
                            id="name"
                            v-model="form.name"
                            name="name"
                            :error="form.errors.name" />
                    </FormField>
                    <FormField
                        label="Description"
                        description="Describe what this saved row is for.">
                        <TextareaField
                            id="description"
                            v-model="form.description"
                            name="description"
                            :error="form.errors.description" />
                    </FormField>
                    <FormField
                        label="Type"
                        description="This will determine the saved row's underlying functionality.">
                        <SelectField
                            id="type"
                            v-model="form.type"
                            name="type"
                            :error="form.errors.type">
                            <option value="feed_story">
                                Story
                            </option>
                            <option value="ad">
                                Advertisement
                            </option>
                        </SelectField>
                    </FormField>
                    <template v-if="form.type === 'feed_story'">
                        <FormField
                            label="Feed Sources">
                            <SelectField
                                id="options.feed_source_ids"
                                v-model="form.options.feed_source_ids"
                                form-contol-class="form-select !h-[30rem]"
                                multiple
                                :error="(form.errors as Record<string,string>)['options.feed_source_ids']">
                                <option
                                    v-for="feedSource in feedSources"
                                    :key="feedSource.id"
                                    :value="feedSource.id">
                                    {{ feedSource.name }}
                                </option>
                            </SelectField>
                        </FormField>
                        <FormField
                            label="Total Days in Feed"
                            description="The number of days a story will remain in the feed.">
                            <InputField
                                id="options.total_days_in_feed"
                                v-model="form.options.total_days_in_feed"
                                :error="(form.errors as Record<string,string>)['options.total_days_in_feed']" />
                        </FormField>
                    </template>
                </template>
            </Card>

            <Card class="bg-white border dark:bg-neutral-950 border-neutral-200 dark:border-neutral-700">
                <template #header>
                    <div
                        class="flex justify-between items-center p-2 border-b border-neutral-200 dark:border-neutral-700">
                        <div class="flex justify-center items-center gap-4">
                            <h2 class="font-bold text-lg">
                                Editor
                            </h2>
                        </div>
                        <div class="flex gap-x-4 items-center">
                            <button
                                v-if="list?.options.smart_merge_tags?.some(item => item.value !== null)"
                                class="hover:underline text-rose-500"
                                @click.prevent="(bee as any)?.toggleMergeTagsPreview()">
                                Toggle Merge Tags
                            </button>
                            <span
                                v-if="form.id && list?.options.smart_merge_tags?.some(item => item.value !== null)"
                                class="text-neutral-500">|</span>
                            <div
                                v-if="form.id"
                                title="When enabled, content in the editor will automatically be saved as you make changes."
                                class="flex items-center justify-end gap-x-2">
                                <label for="autosave">
                                    Auto Save
                                </label>
                                <AutoSave
                                    id="autosave"
                                    :auth-user="authUser" />
                            </div>
                        </div>
                    </div>
                </template>
                <template #body>
                    <BeeEditor
                        ref="bee"
                        v-model="form.template_json"
                        :auth-user="authUser"
                        :list="list"
                        :config="beeConfig(form.type)"
                        :model="form as any"
                        @load="onLoadBee"
                        @update:model-value="onChangeBee"
                        @save="onSaveBee" />
                </template>
            </Card>
        </div>
    </FormLayout>
</template>