<script lang="ts" setup>
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import { echo } from '../plugins/Echo';

const newVersion = ref<string>();

echo.channel('manifest')
    .listen('NewVersionAvailable', ({ version }: {version: string}) => {
        newVersion.value = version;
    });

function onClickRefresh() {
    window.location.reload();
}
</script>

<template>
    <div
        v-if="newVersion"
        class="mx-auto w-full bg-emerald-500 flex h-14 items-center justify-center text-white gap-x-2">
        <ExclamationTriangleIcon class="size-6" /> A new version ({{ newVersion }}) of Alchemy is available.
        <button
            class="btn btn-xs btn-white"
            @click="onClickRefresh">
            Reload
        </button>
    </div>
</template>