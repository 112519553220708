<script lang="ts" setup>
import { Card } from '@vue-interface/card';
import { InputField } from '@vue-interface/input-field';
import { SelectField } from '@vue-interface/select-field';
import { useColorMode } from '@vueuse/core';
import { useForm } from 'laravel-precognition-vue-inertia';
import FormField from '../components/FormField.vue';
import FormLayout from '../components/FormLayout.vue';
import { route } from '../composables/routes';
import type { User } from '../types';

const props = defineProps<{
    user: User;
}>();

const mode = useColorMode({
    emitAuto: true,
});

const timezones = {
    'America/Chicago': 'Central',
    'America/Denver': 'Mountain',
    'America/Los_Angeles': 'Pacific',
    'America/New_York': 'Eastern',
    'America/Phoenix': 'MST - AZ (except Navajo)',
};

const form = useForm('put', route('account.update'), {
    name: props.user.name,
    email: props.user.email,
    password: undefined,
    password_confirmation: undefined,
    timezone: props.user.timezone
});

function onSubmit() {
    form.submit('put', route('account.update'), {
        onSuccess: () => {
            form.reset('password');
            form.reset('password_confirmation');
        },
        preserveScroll: true,
    });
}
</script>

<template>
    <FormLayout
        :submit="onSubmit"
        :disabled="form.processing"
        button-label="Save">
        <div class="flex flex-col gap-y-4">
            <Card>
                <FormField
                    label="Name"
                    description="Your first and last name.">
                    <InputField
                        id="name"
                        v-model="form.name"
                        name="name"
                        :error="form.errors.name"
                        @change="form.validate('name')" />
                </FormField>
                <FormField
                    label="Email"
                    description="The email address of the user.">
                    <InputField
                        id="email"
                        v-model="form.email"
                        name="email"
                        autocomplete="email"
                        :error="form.errors.email"
                        @change="form.validate('email')" />
                </FormField>
                <FormField
                    label="Password"
                    description="If left blank, the password will not be changed.">
                    <InputField
                        id="password"
                        v-model="form.password"
                        type="password"
                        name="password"
                        :error="form.errors.password"
                        @change="form.validate('password')" />
                </FormField>
                <FormField
                    label="Confirm Password"
                    description="Confirm the password if you want to change it.">
                    <InputField
                        id="password_confirmation"
                        v-model="form.password_confirmation"
                        type="password"
                        name="password_confirmation"
                        :error="form.errors.password_confirmation"
                        @change="form.validate('password_confirmation')" />
                </FormField>
            </Card>

            <Card>
                <FormField
                    label="Color Scheme"
                    description="Change the default color scheme to light, dark, or auto.">
                    <SelectField
                        id="color_scheme"
                        v-model="mode"
                        name="color_scheme">
                        <option value="auto">
                            Auto
                        </option>
                        <option value="light">
                            Light
                        </option>
                        <option value="dark">
                            Dark
                        </option>
                    </SelectField>
                </FormField>
                <FormField
                    label="Timezone"
                    description="Personalize the timezone by overriding the server default.">
                    <SelectField
                        id="timezone"
                        v-model="form.timezone"
                        name="timezone"
                        :error="form.errors.timezone"
                        @update:model-value="form.validate('timezone')">
                        <option
                            v-for="(label, value) in timezones"
                            :key="value"
                            :value="value">
                            {{ label }}
                        </option>
                    </SelectField>
                </FormField>
            </Card>
        </div>
    </FormLayout>
</template>