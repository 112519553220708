import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { type App } from 'vue';
import channels from '../channels';

export interface EchoOptions {
    pusher: {
        host?: string,
        port?: number,
        force_tls?: boolean
    }
};

export type UseEchoDeclarationSchema = Record<string, Record<any,UseEchoEvents>>;
export type EchoChannelName = 'private' | 'channel' | 'persistence';
export type UseEchoEvents = Record<any, UseEchoCallback>
export type UseEchoCallback = (...args: any[]) => void;

export type UseEchoDeclarationFunction<T = any> = (context: T) => UseEchoDeclarationSchema;

// Dummy echo object if the driver isn't set to pusher...
export let echo: Echo<any> = new Echo({
    broadcaster: 'null'
});

export function listen(value: UseEchoDeclarationSchema): void {
    for(const [ method, channels ] of Object.entries(value)) {
        if(!channels) {
            continue;
        }

        for(const [ channel, listeners ] of Object.entries(channels)) {
            Object.entries(listeners).reduce((channel, [subject, callback]) => {
                return channel.listen(subject, callback);
            }, echo[method as keyof Echo<any>](channel));
        }
    }
}

export function stopListening(value: UseEchoDeclarationSchema): void {
    for(const [ method, channels ] of Object.entries(value)) {
        if(!channels) {
            continue;
        }

        for(const [ channel, listeners ] of Object.entries(channels)) {
            Object.entries(listeners).reduce((channel, [subject, callback]) => {
                return channel.stopListening(subject, callback);
            }, echo[method as keyof Echo<any>](channel));
        }
    }
}

export default (Vue: App, props: any) => {
    if(props.pusher.broadcaster !== 'pusher') {
        return;
    }

    const client = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
        authEndpoint: '/broadcasting/auth',
        userAuthentication: {
            transport: 'ajax',
            endpoint: '/broadcasting/user-auth'
        },
        wsHost: import.meta.env.VITE_PUSHER_HOST,
        wsPort: import.meta.env.VITE_PUSHER_PORT,
        wssPort: import.meta.env.VITE_PUSHER_PORT,
        forceTLS: import.meta.env.VITE_PUSHER_USE_TLS === 'true',
        // scheme: import.meta.env.VITE_PUSHER_SCHEME,
        disableStats: import.meta.env.VITE_PUSHER_DISABLE_STATS !== 'false',
    });

    echo = new Echo({ broadcaster: props.pusher.broadcaster, client });

    if(props.authUser) {
        channels({ echo, props });
    }
};