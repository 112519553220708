<script lang="ts" setup>
import { Head } from '@inertiajs/vue3';
import { SlidePanelContainer } from '@vue-interface/slide-panel';
import { useColorMode } from '@vueuse/core';
import type { Agency, SubscriberList, User } from 'types';
import { computed, defineProps, watch, watchEffect } from 'vue';
import { useToast } from 'vue-toastification';
import axios from '../axios';
import Footer from '../components/Footer.vue';
import Impersonate from '../components/Impersonate.vue';
import MaintenanceMode from '../components/MaintenanceMode.vue';
import Navigation, { type Breadcrumb } from '../components/Navigation.vue';
import NewVersionAvailable from '../components/NewVersionAvailable.vue';

export type LayoutProps = {
    authUser: User;
    breadcrumbs?: Breadcrumb[];
    hideBreadcrumbs?: boolean;
    hideLists?: boolean,
    fullScreen?: boolean;
    flash: any;
    agency?: Agency;
    list?: SubscriberList;
    csrfToken: string;
    impersonator: any;
    canViewAny: any;
    canView: any;
    foo?: boolean
};

const props = defineProps<LayoutProps>();

const mode = useColorMode();
const toast = useToast();

watch(() => props.flash, (value) => {
    if(value.error) {
        toast.error(value.error);
    }
    if(value.warning) {
        toast.warning(value.warning);
    }
    if(value.success) {
        toast.success(value.success);
    }
});

const csrfToken = computed(() => {
    return props.csrfToken;
});

watchEffect(() => {
    axios.defaults.headers['X-CSRF-TOKEN'] = csrfToken.value;
});
</script>

<template>
    <Head>
        <meta
            name="color-scheme"
            :content="mode">
    </Head>
    <div class="flex min-h-screen flex-col bg-neutral-50 dark:bg-neutral-900">
        <MaintenanceMode />
        <SlidePanelContainer />
        <NewVersionAvailable />
        <Impersonate
            v-if="!!impersonator"
            :impersonator="impersonator"
            :auth-user="authUser" />

        <slot name="navigation">
            <Navigation
                :hide-breadcrumbs="hideBreadcrumbs"
                :list="list"
                :agency="agency"
                :can-view="canView"
                :can-view-any="canViewAny"
                :auth-user="authUser"
                :breadcrumbs="breadcrumbs" />
        </slot>

        <main
            :class="{
                'flex flex-col': fullScreen,
            }"
            class="flex-1 w-full px-5 md:px-8">
            <slot />
        </main>

        <Footer />
    </div>
</template>