<script lang="ts" setup>
import { SelectField } from '@vue-interface/select-field';
import type { Agency, AgencyUser, User } from 'types';
import { ref } from 'vue';
import { route } from '../composables/routes';
import useResourceForm from '../composables/useResourceForm';
import Autocomplete from './Autocomplete.vue';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';
import UserAbilitiesField from './UserAbilitiesField.vue';

const props = defineProps<{
    agency?: Agency
    agencyUser?: AgencyUser;
    user: User;
    userAgencies?: (Agency & { pivot: AgencyUser })[];
}>();

const emit = defineEmits(['success', 'error']);
const activity = ref(false);

const url = route('api.users.agencies.store', {
    user: props.user.id
});

const { form, errors, request } = useResourceForm(url, {
    method: 'post',
    data: {
        agency_id: undefined,
        email: undefined,
        role: undefined,
        abilities: undefined,
        ...props.agencyUser
    }
});

function onSubmit() {
    activity.value = true;
    request()
        .then((response) => {
            emit('success', response);
        }).catch((error) => {
            emit('error', error);
        }).finally(() => {
            activity.value = false;
        });
}
</script>

<template>
    <div class="w-[25rem]">
        <div class="flex flex-col gap-y-1">
            <h2 class="text-3xl font-semibold">
                {{ agencyUser?.role ? 'Edit Permissions' : 'Assign User' }}
            </h2>
            <h3 class="text-neutral-400 dark:text-neutral-500 font-semibold">
                {{ agency?.name }}
            </h3>
        </div>
        <FormLayout
            :submit="onSubmit"
            :activity="activity"
            :disabled="activity"
            :button-block="true"
            :button-label="agencyUser?.role ? 'Save' : 'Submit'">
            <FormField
                v-if="!agencyUser"
                label="Name"
                description="The agency you wish to assign to the user."
                block>
                <Autocomplete
                    id="agency_id"
                    v-model="form.agency_id"
                    name="agency_id"
                    namespace="user-agencies"
                    only="userAgencies"
                    :response="userAgencies"
                    :errors="errors" />
            </FormField>
            <FormField
                label="Role"
                description="The role will determine how the user may interact with the agency."
                block>
                <SelectField
                    id="role"
                    v-model="form.role"
                    name="role"
                    :errors="errors">
                    <option value="admin">
                        Admin
                    </option>
                    <option value="user">
                        User
                    </option>
                </SelectField>
            </FormField>
        </FormLayout>
        <FormField
            v-if="form.role === 'user'"
            label="Abilities"
            description="The abilities give users privileges in the app."
            block>
            <UserAbilitiesField v-model="form.abilities" />
        </FormField>
    </div>
</template>