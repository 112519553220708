<script lang="ts" setup>
import type { IEntityContentJson } from '@mailupinc/bee-plugin/dist/types/bee';
import { Card } from '@vue-interface/card';
import { InputField } from '@vue-interface/input-field';
import { useForm } from 'laravel-precognition-vue-inertia';
import type { Agency, SubscriberList, Template, User } from 'types';
import { ref } from 'vue';
import AutoSave from './AutoSave.vue';
import BeeEditor from './BeeEditor.vue';
import FormField from './FormField.vue';
import FormLayout from './FormLayout.vue';

const props = defineProps<{
    agency: Agency;
    template?: Template;
    url: string
    authUser: User
    list: SubscriberList;
}>();

const method = props.template?.id ? 'put' : 'post';
const bee = ref();
const beeLoaded = ref(false);

const form = useForm(method, props.url, {
    name: props.template?.name,
    template_json: props.template?.template_json ?? {},
    html: props.template?.html,
});

function onSubmit() {
    bee.value.bee.save();

    return false;
}

function onSaveBee(templateJson: IEntityContentJson, html: string) {
    form.template_json = templateJson;
    form.html = html;

    form.submit();
}

function onChangeBee(templateJson: IEntityContentJson) {
    form.template_json = templateJson;

    if(props.template?.id && props.authUser.options.auto_save) {
        form.patch(props.url, {
            preserveScroll: true,
        });
    }
}

const beeConfig = {
    mergeTags: props.list?.options.smart_merge_tags
};
</script>

<template>
    <FormLayout
        :submit="onSubmit"
        :disabled="!beeLoaded">
        <div class="flex flex-col gap-y-8">
            <Card class="bg-white dark:bg-neutral-950 border border-neutral-200 dark:border-neutral-700">
                <!-- <template #header>
                    <h2 class="p-2 font-bold text-lg border-b border-neutral-200 dark:border-neutral-700">
                        Name
                    </h2>
                </template> -->
                <template #body>
                    <FormField
                        label="Name"
                        description="The name of the template.">
                        <InputField
                            id="name"
                            v-model="form.name"
                            name="name"
                            :error="form.errors.name"
                            @change="form.validate('name')" />
                    </FormField>
                </template>
            </Card>

            <Card class="bg-white border dark:bg-neutral-950 border-neutral-200 dark:border-neutral-700">
                <template #header>
                    <div
                        class="flex justify-between items-center p-2 border-b border-neutral-200 dark:border-neutral-700">
                        <div class="flex justify-center items-center gap-4">
                            <h2 class="font-bold text-lg">
                                Editor
                            </h2>
                        </div>
                        <div class="flex gap-x-4 items-center">
                            <button
                                v-if="list?.options.smart_merge_tags?.some(item => item.value !== null)"
                                class="hover:underline text-rose-500"
                                @click.prevent="bee.toggleMergeTagsPreview()">
                                Toggle Merge Tags
                            </button>
                            <span
                                v-if="template?.id && list?.options.smart_merge_tags?.some(item => item.value !== null)"
                                class="text-neutral-500">|</span>
                            <div
                                v-if="template?.id"
                                title="When enabled, content in the editor will automatically be saved as you make changes."
                                class="flex items-center justify-end gap-x-2">
                                <label for="autosave">
                                    Auto Save
                                </label>
                                <AutoSave
                                    id="autosave"
                                    :auth-user="authUser" />
                            </div>
                        </div>
                    </div>
                </template>
                <template #body>
                    <BeeEditor
                        ref="bee"
                        v-model="form.template_json"
                        :auth-user="authUser"
                        :list="list"
                        :config="beeConfig"
                        :model="form as any"
                        @load="beeLoaded = true"
                        @save="onSaveBee"
                        @update:model-value="onChangeBee" />
                </template>
            </Card>
        </div>
    </FormLayout>
</template>
