<script lang="ts" setup>
import { LightSwitchField } from '@vue-interface/light-switch-field';
import { useForm } from 'laravel-precognition-vue-inertia';
import { computed } from 'vue';
import { route } from '../composables/routes';
import type { User } from '../types';

const props = defineProps<{
    id: string;
    authUser: User,
    only?: string|string[];
}>();

const form = useForm('patch', route('account.update'), {
    options: {
        auto_save: props.authUser.options.auto_save
    }
});

const only = computed(() => {
    if(Array.isArray(props.only)) {
        return [...props.only];
    }

    if(props.only) {
        return [props.only];
    }

    return [];
});

function update() {
    form.patch(route('account.update'), {
        preserveScroll: true,
        only: only.value
    });
}

</script>

<template>
    <LightSwitchField
        :id="id"
        v-model="form.options.auto_save"
        name="options.auto_save"
        class="light-switch-field-md"
        :off-value="false"
        :on-value="true"
        :error="(form.errors as Record<string,string>)['options.auto_save']"
        @update:model-value="update" />
</template>